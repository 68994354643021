/**
 * A file that contains most of the Vue Components and logic
 */
import Vue from "vue";
import {store} from "./Vuex/store.js";
import { MediaQueries } from "vue-media-queries";

window.Vue = Vue;
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

Vue.config.productionTip = false;

const VueInstance = new Vue({
    store,
    mediaQueries: mediaQueries,
    created() {
        // Predefine the Viewport
        const vp = this.$mq.above(1200) ? "desktop" : "mobile";
        this.setupViewport(vp);
        
        window.addEventListener("resize", () => {
            /*
             * Need to update the viewport only if it changes
             */
            const vp = this.$mq.above(1200) ? "desktop" : "mobile";
            if (vp !== this.viewport) {
                this.setupViewport(vp);
            }
        
        });
    },
    methods: {
        setupViewport(viewport) {
            return this.$store.commit('adjustViewport', viewport);
        },
    },
    computed: {
        viewport() {
            return this.$store.state.viewport;
        },
    }
});

if (document.getElementById("vue-blog-container") !== null) {
    const BlogContainer = Vue.component("BlogContainer", require('./Components/Blog.vue').default);
    const BlogContainerElm = new Vue({
        el: "#vue-blog-container",
        store,
        mediaQueries: mediaQueries,
        render: h => h(BlogContainer)
    });
}

if(document.getElementById("vue-category-container") !== null) {
    const BlogContainer = Vue.component("BlogContainer", require('./Components/Category.vue').default);
    const BlogContainerElm = new Vue({
        el: "#vue-category-container",
        store,
        mediaQueries: mediaQueries,
        render: h => h(BlogContainer)
    });
}

if(document.getElementById("vue-search-container") !== null) {
    const BlogContainer = Vue.component("BlogContainer", require('./Components/Search.vue').default);
    const BlogContainerElm = new Vue({
        el: "#vue-search-container",
        store,
        mediaQueries: mediaQueries,
        render: h => h(BlogContainer)
    });
}
