<template>
    <div>
        <Post v-if="posts.length"
              v-for="({date, excerpt, id, permalink, title}) in posts"
              :key="id"
              :date="date"
              :excerpt="excerpt"
              :permalink="permalink"
              :title="title" />
        <NoResults v-if="!posts.length && !isLoading"
                   container-class="no-results__container"
                   paragraph-class="no-results__container-text"
                   :no-results-text="noResultsText" />
        
        <Loading v-if="!posts.length"
                 :is-loading="isLoading" />
    
    </div>
</template>

<script type="application/javascript">
    import Loading from "../Blocks/Loading.vue";
    import Post from "./Post.vue";
    import NoResults from "../Blocks/NoResults.vue";
    
    export default {
        props: {
            isLoading: Boolean,
            posts: [Array, Object],
            noResultsText: String,
        },
        components: {
            NoResults,
            Loading,
            Post
        },
        name: "PostsContainer"
    };
</script>

<style scoped>

</style>
