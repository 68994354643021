import Vue from "vue";
import Vuex from "vuex";

import * as mutations from "../Vuex/mutations";
import * as actions from "../Vuex/actions";
import * as getters from "../Vuex/getters";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        viewport: "desktop",
        searchTerm: '',
        searchCatId: 0,
    },
    mutations,
    actions,
    getters,
});
