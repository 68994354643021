<template>
    <div class="row blog-section-container">
        <div class="wrapper">
            <section id="blog-section" class="blog-section">
                <!-- <div class="headline-container">
                    <h2 class="hdr-xsm" v-html="blogTitle" />
                    <p class="body-copy-sm" v-html="`${totalPosts} Posts`" />
                </div> -->

                <!-- Only appears on desktop -->
                <div class="posts-grid">

                <div v-if="this.hideFilters != '1' && this.hideFilters != 1">
                    <Filters
                            :cats="cats"
                            :show-on-desktop="true"
                            :titles="titles"
                            :total-posts="allTotalPosts"
                            :viewport="viewport"
                            :defaultFilterID="defaultFilterID"
                            @searchText="searchText"
                            @searchCat="searchCat"
                            @reset="reset" />
                </div>

                <Post v-if="posts.length"
                    v-for="({author, categories, date, excerpt, id, image, permalink, title}) in posts"
                    :key="id"
                    :author="author"
                    :categories="categories"
                    :date="date"
                    :excerpt="excerpt"
                    :image="image"
                    :permalink="permalink"
                    :title="title" />

                <NoResults v-if="!posts.length && !isLoading"
                        container-class="no-results__container"
                        paragraph-class="no-results__container-text"
                        :no-results-text="noResultsText" />
                
                <Loading v-if="!posts.length"
                        :is-loading="isLoading" />
                </div>

                <Paginate v-show="maxPages > 0"
                          :page-count="maxPages"
                          :page-range="8"
                          :force-page="page"
                          :value="page"
                          :margin-pages="1"
                          :click-handler="gotoPage"
                          :no-li-surround="true"
                          container-class="pagination"
                          container-id="pagination"
                          page-link-class="page page-numbers"
                          next-link-class="next page page-number"
                          :next-text="`<span class='screen-reader-text'>Next</span><img src='${icons.linkArrow}' alt='Next Page' />`"
                          prev-link-class="prev page page-number"
                          :prev-text="`<span class='screen-reader-text'>Prev</span><img src='${icons.linkArrow}' alt='Previous Page' />`"
                          active-class="current"
                          break-view-link-class="page-numbers dots" />
            </section>
        </div>
    </div>
</template>

<script type="application/javascript">
    // Tools
    import axios from "axios";
    import mixins from "../Util/mixins.js";
    // import VueScrollTo from "vue-scrollto";

    // Components
    import Filters from "./Blog/Filters.vue";
    import Paginate from "vuejs-paginate";
    import PostsContainer from "./Blog/PostsContainer.vue";
    import Loading from "./Blocks/Loading.vue";
    import Post from "./Blog/Post.vue";
    import NoResults from "./Blocks/NoResults.vue";

    export default {
        data() {
            return {
                api: CATEGORY.api,
                allTotalPosts: 0,
                blogTitle: CATEGORY.titles.blogTitle,
                cats: CATEGORY.cats,
                catId: CATEGORY.defaultFilterID !== '-1' && CATEGORY.defaultFilterID !== -1 ? CATEGORY.defaultFilterID : CATEGORY.parentCatID,
                parentCatID: CATEGORY.parentCatID,
                defaultFilterID: CATEGORY.defaultFilterID,
                defaultBlogTitle: CATEGORY.titles.blogTitle,
                icons: CATEGORY.icons,
                isLoading: true,
                maxPages: 0,
                noResultsText: CATEGORY.noResultsText,
                page: 1,
                posts: [],
                searchTerm: "",
                titles: CATEGORY.titles,
                totalPosts: 0,
                hideFilters: CATEGORY.hideFilters ? CATEGORY.hideFilters : 0,
                init: true,
            };
        },
        created() {
            this.getBlogPosts();
        },
        mounted() {
            this.moveForm();
            this.geturlhash();
        },
        methods: {
            /**
             * Grabs the posts, and adjusts the filter as needed
             * @param filter
             * @param page
             */
            getBlogPosts(filter = false, page = 1) {
                let api = this.api;
                let config;
                this.isLoading = true;
                this.posts = [];
                config = {
                    params: {
                        posts_per_page: 5
                    }
                };

                if (this.catId) config.params.catId = this.catId;

                if(this.init) {
                    this.init = false;
                    if(this.defaultFilterID !== -1 && this.defaultFilterID !== '-1') {
                        this.$store.commit('setSearchCatId', this.defaultFilterID);
                    }
                }

                axios.get(`${api + page}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.posts = data.posts;
                             this.maxPages = data.maxPages;
                             this.totalPosts = data.total;
                             if (!filter) this.allTotalPosts = data.total;
                         }
                     })
                     .catch(err => console.error(err))
                     .finally(() => {
                         if (filter) this.scrollPageAfterSubmit();
                         this.isLoading = false
                     });
                return;
            },
            /**
             * The method that is triggered by the pagination to grab the next page results
             * @param pageNum
             */
            gotoPage(pageNum) {
                // const resultsElm = $("#blog-section");

                // if (event !== undefined) {
                //     // Add animate scroll
                //     const eventClassList = event.currentTarget.classList;
                //     if (eventClassList.contains("page") && !eventClassList.contains("current")) {
                //         this.scrollPageAfterSubmit();
                //     }
                // }

                /**
                 * Grab our posts
                 */
                this.page = pageNum;
                this.getBlogPosts(true, pageNum);

            },
            /**
             *  The method that manages the v-model of selectedCatId to
             *  setup the filter for categories
             * @param catId
             * @param catName
             */
            searchCat({catId, catName}) {
                this.blogTitle = `Searching: ${catName}`;
                this.$store.commit('setSearchCatId', catId);
                this.catId = catId;
                this.page = 1;
                this.getBlogPosts(true, 1);
            },
            /**
             * The method that manages the v-model of search text
             * @param ctx
             */
            searchText({search}) {
                if (!search) return;
                this.blogTitle = `Searching: ${search}`;
                // this.$store.dispatch('setSearchTerm', {searchTerm: ctx});
                this.searchTerm = search;
                this.page = 1;
                this.getBlogPosts(true, 1);
            },
            /**
             * Form had no way to reset, so added in this method and tied it to a reset button
             */
            reset() {
                console.log('reset', this.parentCatID);
                this.blogTitle = this.defaultBlogTitle;
                this.page = 1;
                this.catId = this.parentCatID;
                this.searchTerm = '';
                this.$store.commit('setSearchCatId', this.parentCatID);
                this.$store.commit('setSearchTerm', '');
                this.getBlogPosts(true, 1);
            },
            /**
             * Method that moves gravity forms from a hidden div to the sidebar
             * @TODO Implement axios + api to submit form
             * Haven't worked with Gravity Forms API and low on time at the moment
             */
            moveForm() {
                const mainElm = document.querySelector(".main");
                let origGF = mainElm.querySelector(".hidden-form-container");
                if (origGF !== null) {
                    const sidebarElm = mainElm.querySelector("#filters-section-desktop");
                    if (sidebarElm) {
                        const gfContainer = sidebarElm.querySelector("#sidebar-newsletter-signup-container");
                        if (gfContainer && gfContainer.childNodes.length === 0) {
                            origGF.classList.remove("hidden-form-container");
                            gfContainer.append(origGF);
                        }
                    }
                }
            },
            /**
             * Moved the scroll animation to it's own method
             */
            scrollPageAfterSubmit() {
                if (document.getElementById("blog-section")) {
                    let offsetAmount = this.$mq.above(1059) ? -75 : -75;
                    this.$scrollTo("#blog-section", 1000, {
                        lazy: false,
                        ease: "ease-in",
                        offset: offsetAmount,
                    });
                }
            },
            geturlhash() {
                const searchURL = new URL(window.location);
                if (searchURL.hash) {
                    console.log(searchURL.hash.substring(1).trim().replace(" ", ""));
                    document.getElementById(searchURL.hash.substring(1).trim().replace(" ", "")).click();

                }
            },
        },
        computed: {
            viewport() {
                return this.$store.state.viewport;
            },
        },
        mixins: [mixins],
        components: {
            Filters,
            Paginate,
            NoResults,
            Loading,
            Post
        },
        name: "Blog"
    };
</script>

<style lang="scss" scoped></style>
