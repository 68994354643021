<template>
    <div :id="`filters-section-${viewportClass}`"
             :class="`filters-section ${!showOnDesktop ? 'responsive' : ''}`">
        <div :class="`filters-container ${viewportClass}`">

            <div class="head">
                <p>Filter by Subcategory</p>
                <button type="reset"
                        id="reset-filters-btn"
                        class="btn-reset"
                        @click.prevent="$emit('reset')">Clear</button>
            </div>
            <!-- Loop through the cat list and include the cat count -->
            <ul>
                <li v-for="(cat) in cats"
                    :key="cat.id">
                    <a href="#"
                       :class="cat.id == searchCatId && 'active'"
                       :data-text="`${cat.name} (${getCatPostCount(cat)})`"
                       :data-cat-id="cat.id"
                       :id="trimTerm(cat.name)"
                       @click.prevent="searchCat(cat.id, cat.name)"
                       v-text="`${cat.name} (${getCatPostCount(cat)})`"
                    />
                    <ul v-if="cat.childCat" :class="'childCatList'">
                        <li v-for="catchild in cat.childCat" :key="catchild.id">
                            <a href="#"
                               :class="catchild.id == searchCatId && 'actives'"
                               :data-text="`${catchild.name} (${getCatPostCount(catchild)})`"
                               :data-cat-id="catchild.id"
                               @click.prevent="searchCat(catchild.id, catchild.name)"
                               v-text="`${catchild.name} (${getCatPostCount(catchild)})`"
                            />
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- <div :class="`blog-search-container ${viewportClass}`">
            <div class="search_wrapper">
                <form role="search"
                      @submit.prevent="search">
                    <div class="search-form">
                        <label for="blog-search"
                               v-text="titles.searchTitle" />
                        <input v-model="searchTerm"
                               id="blog-search"
                               type="search"
                               :placeholder="titles.searchPlaceholder"
                               @keypress.enter.prevent="search" />
                        <button class="button primary search-btn">Search</button>
                    </div>
                </form>
                <button type="reset"
                        id="reset-filters-btn"
                        class="btn btn-reset button secondary"
                        @click.prevent="$emit('reset')">Reset All Filters</button>
            </div>
        </div>
        <div v-if="showOnDesktop"
             id="sidebar-newsletter-signup-container" /> -->
    </div>
</template>

<script type="application/javascript">
    import mixins from "../../Util/mixins.js";

    export default {
        props: {
            cats: [Array, Object],
            showOnDesktop: Boolean,
            titles: [Array, Object],
            totalPosts: [Number, String],
            viewport: String,
            defaultFilterID: String
        },
        data() {
            return {
                viewportClass: this.showOnDesktop ? 'desktop' : 'responsive',
                // selectedCatId: 0,
                searchText: '',
            }
        },
        methods: {
            getCatPostCount(obj) {
                const {postCount} = obj;
                if (postCount === undefined) return this.totalPosts;

                return postCount;
            },
            searchCat(catId, catName) {
                // this.selectedCatId = catId;
                this.$emit('searchCat', {catId: catId, catName: catName});
            },
            search() {
                this.$store.commit('setSearchTerm', this.searchText);
                this.$emit('searchText', {search: this.searchTerm});
            },
            trimTerm(name){
               return name.trim().replace(" ", "");
            },

        },
        computed: {
            searchTerm: {
                get() {
                    return this.$store.state.searchTerm;
                },
                set(newSearchText) {
                    this.searchText = newSearchText;
                }
            },
            searchCatId: {
                get() {
                    return this.$store.state.searchCatId;
                },
            },

        },
        mixins: [mixins],
        name: "Filters"
    };
</script>

<style scoped>

</style>
