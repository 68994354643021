<template>
    <div class="col">
        <div class="card overlay">
            <a :href="permalink" v-text="title" />
            <p v-if="categories"
                class="super-header"
                v-text="categories" />
            <h3 class="hdr-sm" v-text="title" />
            <span aria-hidden="true" class="decorative-link" />
        </div>
    </div>
</template>

<script type="application/javascript">
    import PostImage from "../Blocks/PostImage.vue";
    import mixins from "../../Util/mixins.js";

    export default {
        methods:{
            formatdate(date) {
                return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"});
            },
        },
        components: {PostImage},
        props: {
            author: String,
            categories: String,
            date: String,
            excerpt: String,
            image: [Array, Object],
            permalink: String,
            title: String,
        },
        mixins: [mixins],
        name: "Post"
    };
</script>

<style scoped>

</style>
