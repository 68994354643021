export function adjustViewport(currentState, viewport) {
    currentState.viewport = viewport;
}

export function setSearchTerm(currentState, searchText) {
    currentState.searchTerm = searchText;
}

export function setSearchCatId(currentState, catId) {
    currentState.searchCatId = catId;
}
