<template>
    <div class="result-item">
        <h3>
            <a :href="permalink" v-text="title" />
        </h3>
        <p class="date" v-text="formatdate(date)" />
        <p class="excerpt" v-text="excerpt" />
    </div>
</template>

<script type="application/javascript">
    import PostImage from "../Blocks/PostImage.vue";
    import mixins from "../../Util/mixins.js";
    
    export default {
        methods:{
            formatdate(date) {
                if(date) {
                    const jsDate = new Date(date);
                    const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    let dayNum = jsDate.getDate();
                    if (dayNum > 3 && dayNum < 21) {
                        dayNum += 'th';
                    };
                    switch (dayNum % 10) {
                        case 1:  
                            dayNum += "st";
                            break;
                        case 2:  
                            dayNum += "nd";
                            break;
                        case 3:  
                            dayNum += "rd";
                            break;
                        default: 
                            if(dayNum < 3 || dayNum > 21) {
                                dayNum += "th";
                            }
                            break;
                    }
                    const dateString = dayNames[jsDate.getDay()] + ' ' + months[jsDate.getMonth()] + ' ' + dayNum + ', ' + jsDate.getFullYear();
                    return dateString;
                } else {
                    return date;
                }
            },
        },
        components: {PostImage},
        props: {
            date: String,
            excerpt: String,
            permalink: String,
            title: String,
        },
        mixins: [mixins],
        name: "Post"
    };
</script>

<style scoped>

</style>
