<template>
    <section id="search-section">
        <div class="wrapper">
            <Filters 
            :show-on-desktop="false"
            :total-posts="totalPosts"
            @searchText="searchText"
            @reset="reset" />
            <PostsContainer 
                :is-loading="isLoading"
                :posts="posts"
                :no-results-text="noResultsText" />
            <Paginate 
                v-show="maxPages > 0"
                :page-count="maxPages"
                :page-range="8"
                :force-page="page"
                :value="page"
                :margin-pages="1"
                :click-handler="gotoPage"
                :no-li-surround="true"
                container-class="pagination"
                container-id="pagination"
                page-link-class="page page-numbers"
                next-link-class="next page page-number"
                :next-text="`<span class='screen-reader-text'>Next</span><img src='${icons.linkArrow}' alt='Next Page' />`"
                prev-link-class="prev page page-number"
                :prev-text="`<span class='screen-reader-text'>Prev</span><img src='${icons.linkArrow}' alt='Previous Page' />`"
                active-class="current"
                break-view-link-class="page-numbers dots" />
        </div>
    </section>
</template>

<script type="application/javascript">
    // Tools
    import axios from "axios";
    import mixins from "../Util/mixins.js";
    
    // Components
    import Filters from "./Search/Filters.vue";
    import Paginate from "vuejs-paginate";
    import PostsContainer from "./Search/PostsContainer.vue";
    
    export default {
        data() {
            return {
                api: SEARCH.api,
                allTotalPosts: 0,
                icons: SEARCH.icons,
                isLoading: true,
                maxPages: 0,
                noResultsText: SEARCH.noResultsText,
                page: 1,
                posts: [],
                searchTerm: "",
                totalPosts: 0,
            };
        },
        created() {
            this.getBlogPosts();
        },
        mounted() {
            this.moveForm();
        },
        methods: {
            /**
             * Grabs the posts, and adjusts the filter as needed
             * @param filter
             * @param page
             */
            getBlogPosts(filter = false, page = 1) {
                let api = this.api;
                let config;
                this.isLoading = true;
                this.posts = [];
                config = {
                    params: {
                        posts_per_page: 6
                    }
                };
                
                if (filter) {
                    if (this.searchTerm) config.params.s = this.searchTerm;
                } else {
                    const urlParams = new URLSearchParams(window.location.search);
                    const myParam = urlParams.get('s');
                    this.searchTerm = myParam;
                    config.params.s = myParam;
                }
                
                axios.get(`${api + page}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.posts = data.posts;
                             this.maxPages = data.maxPages;
                             this.totalPosts = data.total;
                             if (!filter) this.allTotalPosts = data.total;
                         }
                     })
                     .catch(err => console.error(err))
                     .finally(() => {
                         if (filter) this.scrollPageAfterSubmit();
                         this.isLoading = false
                     });
                return;
            },
            /**
             * The method that is triggered by the pagination to grab the next page results
             * @param pageNum
             */
            gotoPage(pageNum) {
                // const resultsElm = $("#blog-section");
                
                // if (event !== undefined) {
                //     // Add animate scroll
                //     const eventClassList = event.currentTarget.classList;
                //     if (eventClassList.contains("page") && !eventClassList.contains("current")) {
                //         this.scrollPageAfterSubmit();
                //     }
                // }
                
                /**
                 * Grab our posts
                 */
                this.page = pageNum;
                this.getBlogPosts(true, pageNum);
                
            },
            /**
             * The method that manages the v-model of search text
             * @param ctx
             */
            searchText({search}) {
                if (!search) return;
                // this.$store.dispatch('setSearchTerm', {searchTerm: ctx});
                this.searchTerm = search;
                this.page = 1;
                this.getBlogPosts(true, 1);
            },
            /**
             * Form had no way to reset, so added in this method and tied it to a reset button
             */
            reset() {
                this.page = 1;
                this.searchTerm = '';
                this.$store.commit('setSearchTerm', '');
                this.getBlogPosts(true, 1);
            },
            /**
             * Method that moves gravity forms from a hidden div to the sidebar
             * @TODO Implement axios + api to submit form
             * Haven't worked with Gravity Forms API and low on time at the moment
             */
            moveForm() {
                const mainElm = document.querySelector(".main");
                let origGF = mainElm.querySelector(".hidden-form-container");
                if (origGF !== null) {
                    const sidebarElm = mainElm.querySelector("#filters-section-desktop");
                    if (sidebarElm) {
                        const gfContainer = sidebarElm.querySelector("#sidebar-newsletter-signup-container");
                        if (gfContainer && gfContainer.childNodes.length === 0) {
                            origGF.classList.remove("hidden-form-container");
                            gfContainer.append(origGF);
                        }
                    }
                }
            },
            /**
             * Moved the scroll animation to it's own method
             */
            scrollPageAfterSubmit() {
                if (document.getElementById("blog-section")) {
                    let offsetAmount = this.$mq.above(1059) ? -75 : -75;
                    this.$scrollTo("#blog-section", 1000, {
                        lazy: false,
                        ease: "ease-in",
                        offset: offsetAmount,
                    });
                }
            }
        },
        computed: {},
        mixins: [mixins],
        components: {
            Filters,
            Paginate,
            PostsContainer,
        },
        name: "Blog"
    };
</script>

<style lang="scss" scoped></style>
