<template>
    <form role="search" @submit.prevent="search">
        <label for="blog-search">Search:</label>
        <input v-model="searchTerm"
            id="blog-search"
            type="text"
            placeholder="Search"
            @keypress.enter.prevent="search" />
        <input type="submit" value="Search" />
    </form>
</template>

<script type="application/javascript">
    import mixins from "../../Util/mixins.js";
    
    export default {
        props: {
            cats: [Array, Object],
            showOnDesktop: Boolean,
            totalPosts: [Number, String],
            viewport: String,
        },
        data() {
            return {
                searchText: '',
            }
        },
        created() {
            this.initSearch();
        },
        methods: {
            search() {
                this.$store.commit('setSearchTerm', this.searchText);
                this.$emit('searchText', {search: this.searchTerm});
                if(this.searchTerm !== '') {
                    window.history.pushState({}, 'Search Results for ' + this.searchTerm, '/?=' + encodeURIComponent(this.searchTerm));
                }
            },
            initSearch() {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get('s');
                if(myParam !== undefined && myParam !== null) {
                    this.searchText = myParam;
                    this.$store.commit('setSearchTerm', this.searchText);
                    this.$emit('searchText', {search: this.searchTerm});
                }
            }
        },
        computed: {
            searchTerm: {
                get() {
                    return this.$store.state.searchTerm;
                },
                set(newSearchText) {
                    this.searchText = newSearchText;
                }
            },
            
        },
        mixins: [mixins],
        name: "Filters"
    };
</script>

<style scoped>

</style>
